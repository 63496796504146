// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  region: 'NA',
  appUrl: 'https://emissions-check.dev.zonarsystems.net',

  apiBase: {
    url: 'https://emissions-check-api.dev.zonarsystems.net/api/v1'
  },

  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-dev.apigee.net'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'development',

  auth: {
    clientID: 'O3BOmAQZKPdAdlCzl0NZIYIi9IYj2Ags',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '7f57da4e-6f1e-4eca-b622-3ab28b78eb86',
    defaultZonarRole: '7bd9af3d-be9b-4fa5-80bf-6e3ec780e3e2',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_3f58bb66_33f7_4dd2_86ca_9140514d10bf_650b07b'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'b98eb354-3b80-421c-a916-54dda63afa57',
    clientToken: 'pub6a4c810b136693403837854ef96b11f6',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'development'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'it-IT', 'fr-FR', 'es-ES'],
    defaultLanguage: 'en-US'
  },

  userPreferenceApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
